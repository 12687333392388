import React from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { Line } from './Line';
import { Steps } from './Steps';

import { ReactComponent as IconArrow } from './icons/arrow-left.svg';
import { ReactComponent as IconBack } from './icons/icon-back.svg';

import classes from './style.module.scss';
import './style.scss';

interface Props {
  flow: string[][];
  logo: React.ReactNode;
  onlyLogo: boolean;
  className?: string;
  hideSteps?: boolean;
  hiddenProgress?: boolean;
  currentStep: number;
  currentPage: string;
  totalSteps: number;
  pagesWithoutProgress?: string[];
  onBackClick: () => void;
  onSkipClick?: () => void;
  hidden: boolean;
  hiddenBackButton?: boolean;
  skipButton?: string;
  isAltBackIcon?: boolean;
}

export const ProgressBar: React.FC<Props> = ({
  flow,
  logo,
  onlyLogo,
  className,
  hideSteps,
  hiddenProgress,
  currentStep,
  currentPage,
  totalSteps,
  pagesWithoutProgress,
  onBackClick,
  onSkipClick,
  hidden,
  hiddenBackButton,
  skipButton,
  isAltBackIcon
}) => {
  const classProgress = classNames(classes.progress, {
    [classes.onlyLogo]: onlyLogo,
    [classes.hidden_Progress]: hiddenProgress,
    [classes.hidden_back_button]: hiddenBackButton,
  });

  return (
    <CSSTransition
      in={!hidden}
      timeout={600}
      classNames='progressbar'
      unmountOnExit
    >
      <div className={classNames(classes['progress-bar'], className)}>
        <div className={classProgress}>
          <div className={classes.container}>
            {!onlyLogo && (<div className={classes.back_btn_wrap}>
              <button className={classes.back_btn} onClick={onBackClick} type="button" >
                {isAltBackIcon ? <IconBack /> : <IconArrow className={classes.icon} />}
              </button></div>)}
            {logo}
            {!onlyLogo && (
              <div className={classes.right_part}>
                <div className={classes.counter}>{!hideSteps && `${currentStep}/${totalSteps}`}</div>
                {skipButton && (<button className={classes.skip_btn} onClick={onSkipClick}>{skipButton}</button>)}
              </div>
            )}
          </div>
          {!onlyLogo && (
            <>
              {flow.length < 2 ? (
                <div className={classes.line}>
                  <Line percent={Math.ceil(currentStep * (100 / totalSteps))} rounded />
                </div>
              ) : (
                <Steps flow={flow} currentPage={currentPage} pagesWithoutProgress={pagesWithoutProgress} />
              )}
            </>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

export {
  getCurrentStepFiltered,
} from './utils';
