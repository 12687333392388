import { useState, useEffect } from 'react';
import queryString from 'query-string';

import Analytics from "@web-solutions/module-analytics";
import Attribution from '@web-solutions/module-attribution';

export const useReturnToApp = ({ category }: { category: string }) => {
  const [link, setLink] = useState('');

  const p = queryString.parse(window.location.search);
  const idfm = p.idfm;

  useEffect(() => {
    Attribution.getLinkToApp()
      .then((l) => {
        setLink(l);
      })
      .catch(() => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReturnAppClick = () => {
    Analytics.trackEvent(category, 'return_to_app');
    window.location.href = link;
  };

  return {
    available: !!link && !!idfm,
    link,
    handleReturnAppClick
  }
}