export const PREFIX_WITH_PAYMENT = '-pp';

export const EVENT_ACTION = {
  OPEN: 'open',
  SUCCESS: 'success',
  ERROR: 'error',
  CLOSE: 'close',
  SKIP: 'skip',
  CLICK: 'click',
  SUBMIT: 'submit',
  CANCEL: 'cancel',
  SELECTED: 'selected',
  BACK: 'back',
};
