import _filter from 'lodash/filter';
import _map from 'lodash/map';

import { createSelector } from 'reselect';

import { t } from '@web-solutions/module-localization';

import { getCurrentStepFiltered } from '@web-solutions/progress-bar';

import { Data } from 'core/interfaces/quiz';

//@ts-ignore
import { selectFlow } from 'src/store/routing/selectors';
//@ts-ignore
import { SCREENS_WITHOUT_PROGRESSBAR } from 'src/constants/routes';

export interface QuizProgress {
  currentPage: string;
  isIncludesPage: boolean;
  total: number;
  currentStepIndex: number;
  count: number;
  prevStep: number;
  nextStep: number;
}

export const getCurrentStepNum = (state: any): number => state.routing.currentStep;

const getCompleted = (state: any): boolean => state.routing.isCompleted;

export const quizProgressSelector = createSelector(
  [getCurrentStepNum, getCompleted, selectFlow],
  (currentItemStep, completed, flowArray): QuizProgress | null => {
    const flowWithProgressbar = flowArray.filter((page: any) => !SCREENS_WITHOUT_PROGRESSBAR.includes(page));
    const currentPage = flowArray[currentItemStep];
    const currentStepIndex = getCurrentStepFiltered(flowArray, currentItemStep, flowWithProgressbar) + 1;

    if (!completed) {
      return ({
        currentPage: currentPage,
        isIncludesPage: flowWithProgressbar.includes(currentPage),
        total: flowWithProgressbar.length,
        currentStepIndex: currentStepIndex,
        count: Math.min(Math.ceil(currentStepIndex * (100 / flowWithProgressbar.length)), 100),
        prevStep: currentItemStep - 1,
        nextStep: currentItemStep + 1,
      });
    } else {
      return null;
    }
  },
);

export interface SelectedAnswers {
  title: string;
  key: string;
}

export const selectedAnswersSelector = createSelector([
  (state: any, page: string) => state.quiz.selectedAnswers[page],
  (state: any, page: string) => page,
],
  (ids: string[], page: string): SelectedAnswers[] => {
    const data = t(`quiz.questions.${page}.answers`, { returnObjects: true }) as unknown as Data['answers'];

    const normalizedData = _map(data, (value, key) => {
      return {
        title: value,
        key: key
      }
    });
    return _filter(normalizedData, (value, key) => ids?.includes(value.key));
  });


