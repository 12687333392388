export const SUBSCRIPTION_NAMES = {
  MAIN: 'MAIN',
  SUCCESS: 'SUCCESS',
  GUIDES: 'GUIDES',
  ADHD_WORKBOOK: 'ADHD_WORKBOOK',
  HABITS_WORKBOOK: 'HABITS_WORKBOOK',
  FOOD_WORKBOOK: 'FOOD_WORKBOOK',
  SLEEP_WORKBOOK: 'SLEEP_WORKBOOK',
  ULTIMATE_GUIDE: 'ULTIMATE_GUIDE',
  PRESUMMARY: 'PRESUMMARY',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
} as const;

type SubscriptionNamesKeys = keyof typeof SUBSCRIPTION_NAMES;
type AfterPurchase = Exclude<typeof SUBSCRIPTION_NAMES[SubscriptionNamesKeys], "MAIN" | "SUCCESS" | "PRESUMMARY">
export type AfterPurchaseRoutes = Array<AfterPurchase>;
export type AfterPurchaseStep = AfterPurchase;

export const QUIZ_NAMES = {
  QUIZ_FACE_READING: 'QUIZ_FACE_READING',
  QUIZ_WELCOME_FOCUS: 'QUIZ_WELCOME_FOCUS',
  QUIZ_WELCOME_PROCRASTINATION: 'QUIZ_WELCOME_PROCRASTINATION',
  QUIZ_WELCOME_ABHD: 'QUIZ_WELCOME_ABHD',
  QUIZ_WELCOME_GENDER: 'QUIZ_WELCOME_GENDER',
  QUIZ_WELCOME_LANDING: 'QUIZ_WELCOME_LANDING',
  QUIZ_WELCOME_AGES: 'QUIZ_WELCOME_AGES',
  QUIZ_EMAIL: 'QUIZ_EMAIL',
  QUIZ_MAGIC: 'QUIZ_MAGIC',
  QUIZ_CHECKMARK_MAGIC_LOADER: 'QUIZ_CHECKMARK_MAGIC_LOADER',
  QUIZ_SURVEY: 'QUIZ_SURVEY',

  QUIZ_GENDER: 'QUIZ_GENDER',
  QUIZ_AGE: 'QUIZ_AGE',
  QUIZ_TRANSITION_POTENTIAL: 'QUIZ_TRANSITION_POTENTIAL',
  QUIZ_EASY_DISTRACTED: 'QUIZ_EASY_DISTRACTED',
  QUIZ_ROUTINE: 'QUIZ_ROUTINE',
  QUIZ_CONSEQUENCES: 'QUIZ_CONSEQUENCES',
  QUIZ_OVERBURDENED: 'QUIZ_OVERBURDENED',
  QUIZ_PROCRASTINATION: 'QUIZ_PROCRASTINATION',
  QUIZ_TRANSITION_VICIOUS_CYCLE: 'QUIZ_TRANSITION_VICIOUS_CYCLE',
  QUIZ_SELF_IMPROVEMENT: 'QUIZ_SELF_IMPROVEMENT',
  QUIZ_DAILY_ROUTINE: 'QUIZ_DAILY_ROUTINE',
  QUIZ_TRANSITION_NEW_HABITS: 'QUIZ_TRANSITION_NEW_HABITS',
  QUIZ_MEETING_FAIRY: 'QUIZ_MEETING_FAIRY',
  QUIZ_QUIT_HABITS: 'QUIZ_QUIT_HABITS',
  QUIZ_WHAT_STOPS: 'QUIZ_WHAT_STOPS',
  QUIZ_TRANSITION_NOT_ALONE: 'QUIZ_TRANSITION_NOT_ALONE',
  QUIZ_IMAGINE: 'QUIZ_IMAGINE',
  QUIZ_INVEST_TIME: 'QUIZ_INVEST_TIME',
  QUIZ_NEW_SELF_CARE: 'QUIZ_NEW_SELF_CARE',
  QUIZ_AFTER_MONTH: 'QUIZ_AFTER_MONTH',
  QUIZ_TRANSITION_START_TODAY: 'QUIZ_TRANSITION_START_TODAY',
  QUIZ_TRANSITION_REVIEW: 'QUIZ_TRANSITION_REVIEW',

  QUIZ_DETOX_ANIMATION_CHART_MONTH: 'QUIZ_DETOX_ANIMATION_CHART_MONTH',
  QUIZ_DETOX_ANIMATION_CHART_WEEKS: 'QUIZ_DETOX_ANIMATION_CHART_WEEKS',

  QUIZ_BURNOUT_ANIMATION_CHART_MONTH: 'QUIZ_BURNOUT_ANIMATION_CHART_MONTH',
  QUIZ_BURNOUT_ANIMATION_CHART_WEEKS: 'QUIZ_BURNOUT_ANIMATION_CHART_WEEKS',

  //== START == Updated Procrastination Quiz == 
  QUIZ_PUTTING_OFF_TASKS: 'QUIZ_PUTTING_OFF_TASKS',
  QUIZ_MORE_INSPIRED: 'QUIZ_MORE_INSPIRED',
  QUIZ_WORRING_ABOUT: 'QUIZ_WORRING_ABOUT',
  QUIZ_LACK_OF_FOCUS: 'QUIZ_LACK_OF_FOCUS',
  QUIZ_DEEP_DOWN: 'QUIZ_DEEP_DOWN',
  QUIZ_PROCRASTINATION_TEND_TASKS: 'QUIZ_PROCRASTINATION_TEND_TASKS',
  QUIZ_PROCRASTINATION_AFFECTS: 'QUIZ_PROCRASTINATION_AFFECTS',
  QUIZ_CASES_IN_RELTIONSHIPS: 'QUIZ_CASES_IN_RELTIONSHIPS',
  QUIZ_RUINING_CAREER: 'QUIZ_RUINING_CAREER',
  QUIZ_PROCRASTINATION_STRUGGLE: 'QUIZ_PROCRASTINATION_STRUGGLE',
  QUIZ_PROCRASTINATION_RATIONALIZE: 'QUIZ_PROCRASTINATION_RATIONALIZE',
  QUIZ_PROCRASTINATION_HANDLE_STRESS: 'QUIZ_PROCRASTINATION_HANDLE_STRESS',
  QUIZ_PROCRASTINATION_WHAT_STOPS: 'QUIZ_PROCRASTINATION_WHAT_STOPS',
  QUIZ_PROCRASTINATION_HOW_FEEL: 'QUIZ_PROCRASTINATION_HOW_FEEL',
  QUIZ_PROCRASTINATION_GOALS: 'QUIZ_PROCRASTINATION_GOALS',

  QUIZ_PROCRASTINATION_TRANSITION_SUCCESS_STORY: 'QUIZ_PROCRASTINATION_TRANSITION_SUCCESS_STORY',
  QUIZ_PROCRASTINATION_TRANSITION_TAIL_TECHNIQUES: 'QUIZ_PROCRASTINATION_TRANSITION_TAIL_TECHNIQUES',
  QUIZ_PROCRASTINATION_TRANSITION_RELAX_TECHNIQUES: 'QUIZ_PROCRASTINATION_TRANSITION_RELAX_TECHNIQUES',
  QUIZ_PROCRASTINATION_TRANSITION_BIG_CHANGES: 'QUIZ_PROCRASTINATION_TRANSITION_BIG_CHANGES',
  QUIZ_PROCRASTINATION_TRANSITION_CONTROL_ENERGY: 'QUIZ_PROCRASTINATION_TRANSITION_CONTROL_ENERGY',
  QUIZ_PROCRASTINATION_TRANSITION_ALMOST_THERE: 'QUIZ_PROCRASTINATION_TRANSITION_ALMOST_THERE',
  ///== END == Updated Procrastination Quiz == 

  QUIZ_TRANSITION_TIME: 'QUIZ_TRANSITION_TIME',
  QUIZ_TRANSITION_WILLPOWER: 'QUIZ_TRANSITION_WILLPOWER',
  QUIZ_TRANSITION_STEPS: 'QUIZ_TRANSITION_STEPS',
  QUIZ_TRANSITION_ALMOST_THERE: 'QUIZ_TRANSITION_ALMOST_THERE',
  QUIZ_TRANSITION_GOT_THIS: 'QUIZ_TRANSITION_GOT_THIS',
  QUIZ_TRANSITION_PROUD: 'QUIZ_TRANSITION_PROUD',

  QUIZ_TIME_IN_PHONE: 'QUIZ_TIME_IN_PHONE',
  QUIZ_STAY_PRODUCTIVE: 'QUIZ_STAY_PRODUCTIVE',
  QUIZ_CANT_STOP_CHECKING: 'QUIZ_CANT_STOP_CHECKING',
  QUIZ_TRACK_OF_TIME: 'QUIZ_TRACK_OF_TIME',
  QUIZ_CONTROL_PHONE_HABITS: 'QUIZ_CONTROL_PHONE_HABITS',
  QUIZ_HELP_FOCUS: 'QUIZ_HELP_FOCUS',
  QUIZ_PHONE_ADDICTION: 'QUIZ_PHONE_ADDICTION',
  QUIZ_FOCUS_AFTER_MONTH: 'QUIZ_FOCUS_AFTER_MONTH',

  QUIZ_TRANSITION_READY_FOR_RESULTS: 'QUIZ_TRANSITION_READY_FOR_RESULTS',

  //== START == Updated ADHD Quiz == 
  QUIZ_ADHD_UNWIND: 'QUIZ_ADHD_UNWIND',
  QUIZ_ADHD_OVERLY_ACTIVE: 'QUIZ_ADHD_OVERLY_ACTIVE',
  QUIZ_ADHD_FORGET_OBLIGATIONS: 'QUIZ_ADHD_FORGET_OBLIGATIONS',
  QUIZ_ADHD_MYSELF_FIDGETING: 'QUIZ_ADHD_MYSELF_FIDGETING',
  QUIZ_ADHD_INTERRUPT_PEOPLE: 'QUIZ_ADHD_INTERRUPT_PEOPLE',
  QUIZ_ADHD_STRUGGLE_FINALIZING_PROJECT: 'QUIZ_ADHD_STRUGGLE_FINALIZING_PROJECT',
  QUIZ_ADHD_CARELESS_MISTAKES: 'QUIZ_ADHD_CARELESS_MISTAKES',
  QUIZ_ADHD_LOSE_TRACK_IN_CONVERSATION: 'QUIZ_ADHD_LOSE_TRACK_IN_CONVERSATION',
  QUIZ_ADHD_STAY_IN_ONE_SPOT: 'QUIZ_ADHD_STAY_IN_ONE_SPOT',
  QUIZ_ADHD_FOCUS_IN_BUSY: 'QUIZ_ADHD_FOCUS_IN_BUSY',
  QUIZ_ADHD_OVERALL_MENTAL_HEALTH: 'QUIZ_ADHD_OVERALL_MENTAL_HEALTH',
  QUIZ_ADHD_GOALS: 'QUIZ_ADHD_GOALS',
  QUIZ_ADHD_ANIMATION_CHART_MONTH: 'QUIZ_ADHD_ANIMATION_CHART_MONTH',
  QUIZ_ADHD_ANIMATION_CHART_WEEKS: 'QUIZ_ADHD_ANIMATION_CHART_WEEKS',

  QUIZ_ADHD_TRANSITION_PROCESSING: 'QUIZ_ADHD_TRANSITION_PROCESSING',
  QUIZ_ADHD_TRANSITION_DONT_STOP_POTENTIAL: 'QUIZ_ADHD_TRANSITION_DONT_STOP_POTENTIAL',
  QUIZ_ADHD_TRANSITION_THRIVE: 'QUIZ_ADHD_TRANSITION_THRIVE',
  QUIZ_ADHD_TRANSITION_NEW_LIFE: 'QUIZ_ADHD_TRANSITION_NEW_LIFE',
  QUIZ_ADHD_TRANSITION_GOT_THIS: 'QUIZ_ADHD_TRANSITION_GOT_THIS',
  //== END == Updated ADHD Quiz == 

  QUIZ_TRANSITION_REVIEW_ADHD: 'QUIZ_TRANSITION_REVIEW_ADHD',

  QUIZ_STRUGGLING_WITH_FOCUSING: 'QUIZ_STRUGGLING_WITH_FOCUSING',
  QUIZ_DISTRACTED_BY_NOISE: 'QUIZ_DISTRACTED_BY_NOISE',
  QUIZ_SITTING_STILL: 'QUIZ_SITTING_STILL',
  QUIZ_DIFFICULTY_CONCENTRATING: 'QUIZ_DIFFICULTY_CONCENTRATING',
  QUIZ_CARELESS_MISTAKES: 'QUIZ_CARELESS_MISTAKES',
  QUIZ_LEAVE_SEAT: 'QUIZ_LEAVE_SEAT',
  QUIZ_STRUGGLE_FINALIZING_PROJECT: 'QUIZ_STRUGGLE_FINALIZING_PROJECT',
  QUIZ_FINISHING_SENTENCES: 'QUIZ_FINISHING_SENTENCES',
  QUIZ_DIFFICULTY_REACTING: 'QUIZ_DIFFICULTY_REACTING',
  QUIZ_DIFFICULT_TO_REMEMBER: 'QUIZ_DIFFICULT_TO_REMEMBER',
  QUIZ_OVERLY_ACTIVE: 'QUIZ_OVERLY_ACTIVE',
  QUIZ_ADHD_SYMPTOMS: 'QUIZ_ADHD_SYMPTOMS',
  QUIZ_STRUGGLE_WITH_ADHD_SYMPTOMS: 'QUIZ_STRUGGLE_WITH_ADHD_SYMPTOMS',
  QUIZ_EXPERIENCE_ADHD_SYMPTOMS: 'QUIZ_EXPERIENCE_ADHD_SYMPTOMS',
  QUIZ_YOUR_EXPECTATIONS: 'QUIZ_YOUR_EXPECTATIONS',


  QUIZ_TRANSITION_NOT_BAD_HABIT: 'QUIZ_TRANSITION_NOT_BAD_HABIT',
  QUIZ_TRANSITION_ATTITUDE: 'QUIZ_TRANSITION_ATTITUDE',
  QUIZ_TRANSITION_JOB_SO_FAR: 'QUIZ_TRANSITION_JOB_SO_FAR',
  QUIZ_TRANSITION_KEEP_GOING: 'QUIZ_TRANSITION_KEEP_GOING',
  QUIZ_TRANSITION_GOOD_HABIT: 'QUIZ_TRANSITION_GOOD_HABIT',
  QUIZ_TRANSITION_SETTING_GOALS: 'QUIZ_TRANSITION_SETTING_GOALS',
  QUIZ_TRANSITION_SEE_PLAN: 'QUIZ_TRANSITION_SEE_PLAN',
  QUIZ_TRANSITION_REVIEW_PROCRASTINATION: 'QUIZ_TRANSITION_REVIEW_PROCRASTINATION',

  QUIZ_LEAVE_THINGS: 'QUIZ_LEAVE_THINGS',
  QUIZ_PROCRASTINATOR: 'QUIZ_PROCRASTINATOR',
  QUIZ_SET_DEADLINES: 'QUIZ_SET_DEADLINES',
  QUIZ_TEND_TO_UNDERESTIMATE: 'QUIZ_TEND_TO_UNDERESTIMATE',
  QUIZ_TIME_BLINDNESS: 'QUIZ_TIME_BLINDNESS',
  QUIZ_OVERWHELMED_BY_TASKS: 'QUIZ_OVERWHELMED_BY_TASKS',
  QUIZ_REWARDS_YOURSELF: 'QUIZ_REWARDS_YOURSELF',
  QUIZ_DELEGATE_TASKS: 'QUIZ_DELEGATE_TASKS',
  QUIZ_EXPECT_MORE: 'QUIZ_EXPECT_MORE',
  QUIZ_FEEL_PROCRASTINATE: 'QUIZ_FEEL_PROCRASTINATE',
  QUIZ_AWAY_RESPONSIBILITY: 'QUIZ_AWAY_RESPONSIBILITY',
  QUIZ_PROCRASTINATION_FREE: 'QUIZ_PROCRASTINATION_FREE',
  QUIZ_NEGATIVE_CONSEQUENCES: 'QUIZ_NEGATIVE_CONSEQUENCES',
  QUIZ_WANT_TO_GET: 'QUIZ_WANT_TO_GET',
  QUIZ_FEEL_STRESSED: 'QUIZ_FEEL_STRESSED',
  QUIZ_MOOD_SWINGS: 'QUIZ_MOOD_SWINGS',
  QUIZ_HAPPINESS_LEVEL: 'QUIZ_HAPPINESS_LEVEL',
  QUIZ_HARD_TO_RELAX: 'QUIZ_HARD_TO_RELAX',
  QUIZ_SELF_ESTEEM: 'QUIZ_SELF_ESTEEM',
  QUIZ_SPEND_WORRYING: 'QUIZ_SPEND_WORRYING',
  QUIZ_HABITS_TO_PROCRASTINATE: 'QUIZ_HABITS_TO_PROCRASTINATE',
  QUIZ_HABITS_TO_QUIT: 'QUIZ_HABITS_TO_QUIT',
  QUIZ_FOLLOW_DAILY_ROUTINE: 'QUIZ_FOLLOW_DAILY_ROUTINE',
  QUIZ_DO_EXERCISE: 'QUIZ_DO_EXERCISE',
  QUIZ_BEDTIME_ROUTINE: 'QUIZ_BEDTIME_ROUTINE',
  QUIZ_PRACTICE_MEDITATION: 'QUIZ_PRACTICE_MEDITATION',
  QUIZ_EAT_AT_TIME: 'QUIZ_EAT_AT_TIME',
  QUIZ_LIVING_SPACE: 'QUIZ_LIVING_SPACE',
  QUIZ_SOCIAL_LIFE: 'QUIZ_SOCIAL_LIFE',
  QUIZ_CANCEL_PLANS: 'QUIZ_CANCEL_PLANS',
  QUIZ_IMPACTS_RELATIONSHIPS: 'QUIZ_IMPACTS_RELATIONSHIPS',
  QUIZ_COMMUNICATE_LOVED_ONES: 'QUIZ_COMMUNICATE_LOVED_ONES',
  QUIZ_CHECK_SOCIAL: 'QUIZ_CHECK_SOCIAL',
  QUIZ_PHONE_NOTIFICATIONS: 'QUIZ_PHONE_NOTIFICATIONS',
  QUIZ_DOOMSCROLLING: 'QUIZ_DOOMSCROLLING',
  QUIZ_SELF_DEVELOPMENT: 'QUIZ_SELF_DEVELOPMENT',
  QUIZ_LONG_TERM_GOALS: 'QUIZ_LONG_TERM_GOALS',
  QUIZ_HOW_OPTIMISTIC: 'QUIZ_HOW_OPTIMISTIC',
  QUIZ_UPDATE_GOALS: 'QUIZ_UPDATE_GOALS',
  QUIZ_STICK_PLANS: 'QUIZ_STICK_PLANS',
  QUIZ_CONTROL_DESTINY: 'QUIZ_CONTROL_DESTINY',
  QUIZ_CURRENT_SITUATION: 'QUIZ_CURRENT_SITUATION',
  QUIZ_MAKE_CHANGES: 'QUIZ_MAKE_CHANGES',

  QUIZ_TRANSITION_WE_PROUD: 'QUIZ_TRANSITION_WE_PROUD',
  QUIZ_TRANSITION_TEMPORARY_HIGH: 'QUIZ_TRANSITION_TEMPORARY_HIGH',
  QUIZ_TRANSITION_DOPAMINE: 'QUIZ_TRANSITION_DOPAMINE',
  QUIZ_TRANSITION_TRAPPED: 'QUIZ_TRANSITION_TRAPPED',
  QUIZ_TRANSITION_THRIVE: 'QUIZ_TRANSITION_THRIVE',
  QUIZ_TRANSITION_SUPPORT_YOU: 'QUIZ_TRANSITION_SUPPORT_YOU',
  QUIZ_TRANSITION_PROCESSING: 'QUIZ_TRANSITION_PROCESSING',
  QUIZ_TRANSITION_NEW_LIFE: 'QUIZ_TRANSITION_NEW_LIFE',
  QUIZ_TRANSITION_UNCOVER_PLAN: 'QUIZ_TRANSITION_UNCOVER_PLAN',
  QUIZ_TRANSITION_MEASURED: 'QUIZ_TRANSITION_MEASURED',
  QUIZ_COPE_EMOTIONS: 'QUIZ_COPE_EMOTIONS',
  QUIZ_KILL_TIME: 'QUIZ_KILL_TIME',
  QUIZ_SHORT_PLEASURE: 'QUIZ_SHORT_PLEASURE',
  QUIZ_SCROLLING_SOCIAL_MEDIA: 'QUIZ_SCROLLING_SOCIAL_MEDIA',
  QUIZ_JUNK_FOOD: 'QUIZ_JUNK_FOOD',
  QUIZ_TEND_OVERSPEND: 'QUIZ_TEND_OVERSPEND',
  QUIZ_DIFFICULT_TO_FOCUS: 'QUIZ_DIFFICULT_TO_FOCUS',
  QUIZ_IMPULSES: 'QUIZ_IMPULSES',
  QUIZ_CRAVE_MORE: 'QUIZ_CRAVE_MORE',
  QUIZ_IMPULSIVITY_TENDENCIES: 'QUIZ_IMPULSIVITY_TENDENCIES',
  QUIZ_DOPAMINE_ADDICTION: 'QUIZ_DOPAMINE_ADDICTION',
  QUIZ_SPEND_ON_SHORT_TERM: 'QUIZ_SPEND_ON_SHORT_TERM',
  QUIZ_IMPULSIVE_BEHAVIOR: 'QUIZ_IMPULSIVE_BEHAVIOR',
  QUIZ_UNHEALTHY_HABIT: 'QUIZ_UNHEALTHY_HABIT',
  QUIZ_REPLACE_HABIT: 'QUIZ_REPLACE_HABIT',
  QUIZ_BIGGEST_CHALLENGE: 'QUIZ_BIGGEST_CHALLENGE',
  QUIZ_LIFESTYLE: 'QUIZ_LIFESTYLE',
  QUIZ_HEALTHY_ROUTINES: 'QUIZ_HEALTHY_ROUTINES',
  QUIZ_AFTER_30_DAYS: 'QUIZ_AFTER_30_DAYS',
  QUIZ_CAUSES_TO_STRUGGLE: 'QUIZ_CAUSES_TO_STRUGGLE',
  QUIZ_TRANSITION_ADHD_SUMMARY: 'QUIZ_TRANSITION_ADHD_SUMMARY',

  QUIZ_TRANSITION_OVST_FEELING_STUCK: 'QUIZ_TRANSITION_OVST_FEELING_STUCK',
  QUIZ_TRANSITION_OVST_CHALLENGING_DISCONNECT: 'QUIZ_TRANSITION_OVST_CHALLENGING_DISCONNECT',
  QUIZ_TRANSITION_OVST_STEAL_TIME: 'QUIZ_TRANSITION_OVST_STEAL_TIME',
  QUIZ_TRANSITION_OVST_PROCESSING: 'QUIZ_TRANSITION_OVST_PROCESSING',
  QUIZ_TRANSITION_OVST_PERFECT_LIFESTYLE: 'QUIZ_TRANSITION_OVST_PERFECT_LIFESTYLE',
  QUIZ_TRANSITION_OVST_GOT_THIS: 'QUIZ_TRANSITION_OVST_GOT_THIS',
  QUIZ_TRANSITION_OVST_READY_TO_CHANGE: 'QUIZ_TRANSITION_OVST_READY_TO_CHANGE',
  QUIZ_TRANSITION_OVST_MEASURED: 'QUIZ_TRANSITION_OVST_MEASURED',

  QUIZ_OVST_PLEASURABLE_STIMULATION: 'QUIZ_OVST_PLEASURABLE_STIMULATION',
  QUIZ_OVST_CANT_KEEP_UP: 'QUIZ_OVST_CANT_KEEP_UP',
  QUIZ_OVST_DIFFICULTY_FOCUSING: 'QUIZ_OVST_DIFFICULTY_FOCUSING',
  QUIZ_OVST_NEED_TO_CHECK_MESSAGES: 'QUIZ_OVST_NEED_TO_CHECK_MESSAGES',
  QUIZ_OVST_SPENDING_TIME_ON_MEDIA: 'QUIZ_OVST_SPENDING_TIME_ON_MEDIA',
  QUIZ_OVST_SYMPTOMS: 'QUIZ_OVST_SYMPTOMS',
  QUIZ_OVST_TENDENCY: 'QUIZ_OVST_TENDENCY',
  QUIZ_OVST_LIFESTYLE: 'QUIZ_OVST_LIFESTYLE',
  QUIZ_OVST_ACHIEVE_AFTER_30_DAYS: 'QUIZ_OVST_ACHIEVE_AFTER_30_DAYS',

  QUIZ_TRANSITION_REVIEW_FROM_CAUSES: 'QUIZ_TRANSITION_REVIEW_FROM_CAUSES',
  QUIZ_TRANSITION_REVIEW_FROM_AFTER_MONTH: 'QUIZ_TRANSITION_REVIEW_FROM_AFTER_MONTH',

  QUIZ_ROUTINE_WHAT_DO_FIRST: 'QUIZ_ROUTINE_WHAT_DO_FIRST',
  QUIZ_ROUTINE_BEGINNING_OF_DAY: 'QUIZ_ROUTINE_BEGINNING_OF_DAY',
  QUIZ_ROUTINE_DAILY_SCHEDULE: 'QUIZ_ROUTINE_DAILY_SCHEDULE',
  QUIZ_ROUTINE_HOW_ENERGIZED: 'QUIZ_ROUTINE_HOW_ENERGIZED',
  QUIZ_ROUTINE_FEEL_OVERWHELMED: 'QUIZ_ROUTINE_FEEL_OVERWHELMED',
  QUIZ_ROUTINE_EASILY_DISTRACTED: 'QUIZ_ROUTINE_EASILY_DISTRACTED',
  QUIZ_ROUTINE_NEVER_HAVE_ENOUGH_TIME: 'QUIZ_ROUTINE_NEVER_HAVE_ENOUGH_TIME',
  QUIZ_ROUTINE_DO_BEFORE_BEDTIME: 'QUIZ_ROUTINE_DO_BEFORE_BEDTIME',
  QUIZ_ROUTINE_STRESS_BINGES: 'QUIZ_ROUTINE_STRESS_BINGES',
  QUIZ_ROUTINE_FINDING_MOTIVATION: 'QUIZ_ROUTINE_FINDING_MOTIVATION',
  QUIZ_ROUTINE_IDEAL_DAILY_ROUTINE: 'QUIZ_ROUTINE_IDEAL_DAILY_ROUTINE',
  QUIZ_ROUTINE_LIVING_BEST_LIFE: 'QUIZ_ROUTINE_LIVING_BEST_LIFE',
  QUIZ_ROUTINE_AFTER_MONTH: 'QUIZ_ROUTINE_AFTER_MONTH',

  QUIZ_ROUTINE_TRANSITION_MORNING_HABITS: 'QUIZ_ROUTINE_TRANSITION_MORNING_HABITS',
  QUIZ_ROUTINE_TRANSITION_CONTROL_ROUTINE: 'QUIZ_ROUTINE_TRANSITION_CONTROL_ROUTINE',
  QUIZ_ROUTINE_TRANSITION_BEHAVIORAL_SCIENCE: 'QUIZ_ROUTINE_TRANSITION_BEHAVIORAL_SCIENCE',
  QUIZ_ROUTINE_TRANSITION_BIG_CHANGES: 'QUIZ_ROUTINE_TRANSITION_BIG_CHANGES',
  QUIZ_ROUTINE_TRANSITION_BEST_VERSION: 'QUIZ_ROUTINE_TRANSITION_BEST_VERSION',
  QUIZ_ROUTINE_TRANSITION_PROCESSING: 'QUIZ_ROUTINE_TRANSITION_PROCESSING',
  QUIZ_ROUTINE_TRANSITION_YOUR_CHOICE: 'QUIZ_ROUTINE_TRANSITION_YOUR_CHOICE',
} as const;

export type HabioFlowStep = typeof QUIZ_NAMES[keyof typeof QUIZ_NAMES];
